import React from "react";
import Banner from "../../assets/Healthcare_bannernew.jpg";
import ServicesSection from "../../Component/ServicesSection";
import { Button } from "react-bootstrap";
import banner1 from "../../assets/con-banner.jpg";
import InstaGal from "../../Component/InstaGal";
import ContLog from "../../Component/ContLog";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Corect from "../../Component/Corect";

const Service = () => {
  return (
    <section>
      <div>
        <Header />
      </div>

      <div className="sce">
        <div className="container">
          <div className="row">
            <div className="col">Services & Solutions</div>
          </div>
        </div>
      </div>
      <div className="sc1">
        <div className="container">
          <div className="row">
            <div className="col">
              Take your company to new heights by investing in our reliable and
              efficient technology solutions.
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="sce-bnnr">
          <div className="container">
            <div className="row">
              <div className="col">
                <img
                  decoding="async"
                  src={Banner}
                  alt="Trusted By 125+ Doctors"
                  className="service-banner"
                />
              </div>
            </div>
          </div>
        </div>  
      </section>
      <div className="sce1">
        <p>
          Medical Sarthi is your trusted partner for IT management, Health Marketing, Web Development,Social Media Management and Branding with one goal in mind. To transform the
          way your business works in order to save you time and money.
        </p>
      </div>
      <div className="sce2 text-center" >
        <h1>Comprehensive Marketing Solution</h1>
      </div>
      <div className="sce-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p>
                When we say comprehensive, we mean comprehensive. Medical Sarthi
                has the experience and expertise to provide an excellent
                experience across spheres, for truly holistic IT.
              </p>
            </div>
            <div className="col-md-6">
              <p>
                Some MSPs struggle to offer consulting or network services. But
                your organization needs the entire experience to stop worrying
                about IT. Learn more about our services below.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesSection />
      </div>
      <div className="sce3 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>Why choose services from Medical Sarthi?</h1>
              <p>
                Our services provide businesses with an edge over the
                competition with a variety of benefits. Opting for outsourced IT
                services improve the efficiency of business and build trust with
                customers and clients. Our services can be tailored to meet
                specific needs to match your specific goals.
              </p>
              <Button>Get in touch</Button>
            </div>
            <div className="col-md-6">
              <img
                decoding="async"
                src={banner1}
                alt="Trusted By 125+ Doctors"
                className="service-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <InstaGal />
      </div>
      <div>
        <ContLog />
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <Corect/>
      </div>
    </section>
  );
};

export default Service;
