import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Landing from "../../Card/Landing";
const Footer = () => {
  return (
    <section>
      <div className="ftt">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <h4>Services</h4>
              <br />
              <span>Medical SEO</span>
              <br />
              <span>Medical Website development</span>
              <br />
              
              <span>Patient Lead Generation</span>
              <br />
              <span className="newseo"><Link to ={Landing}>Landing Page Development</Link></span>
              <br />
             
              <span>Online Reputation Management</span>
              
              <br />
              <span>Social Media Management</span>
              <br />
              <span>News Press Release</span>
              <br />
              <span>Advertisement & Content Shoot</span>
            </div>

            <div className="col-md-3">
              <h4>Company</h4>
              <br />
              <span>About Us</span>
              <br />
              <span>Team</span>
              <br />
              <span>Reviews & Awards</span>
              <br />
              <span>Privacy Policy</span>
              <br />
              <span>Terms & Condition</span>
            </div>
            <div className="col-md-3">
              <h4>Partnership and Certification</h4>
             
              <span>ISO Certified</span>
              <br />
              <span>Digital India</span>
              <br />
              <span>MSME</span>
              <br />
              <span>Meta Partner Agency</span>
              <br />
              <span>Google Partner Agency</span>
            </div>
            <div className="col-md-3">
              <h4>Join Us</h4>
              <br />
              <span>Careers</span>
              <br />
              <span>Open Positions</span>
              <br /> Emaill Id: &nbsp;
              <Link to="/" style={{display: 'inline-block'}}>
    info@medicalsarthi.in
  </Link>              <br />
              {/* <button>We are hiring</button> */}
            </div>
                <div className="container-fluid pt-3">
                  <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                      <span className="soc">
                        <Link to={'/'}>
                        <FaLinkedin />
                        </Link>
                        
                      </span>
                      <span className="soc">
                      <Link to={'https://www.instagram.com/medical.sarthi'}>
                        <FaInstagram />
                        </Link>
                      </span>
                      <span className="soc">
                        <FaFacebook />
                      </span>
                      <span className="soc">
                        <FaXTwitter />
                      </span>
                      <span className="soc">
                        <FaYoutube />
                      </span>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
