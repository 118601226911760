import React, { useState, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Banner12 from "../../assets/newlogom.webp";
import Banner1 from "../../assets/Banner1.png";
import { NavLink, Link } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { color } from "framer-motion";


const Header = () => {
  const navRef = useRef();
  const [isOpen, setIsOpen] = useState(false); // State for dropdown toggle

  // Function to show/hide navbar on mobile
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  // Function to toggle dropdown on mobile
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {/* Desktop Header */}
      <section className="desk-header">
        <header>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-2">
                <Link to="/">
                  <img
                    width="160"
                    height="50"
                    src={Banner12}
                    className="site-logo"
                    alt="Clinic Manager Logo"
                  />
                </Link>
              </div>
              <div className="col-md-6">
                <nav ref={navRef} className="head-nav">
                  <ul className="menu">
                    <li className="menu-item">
                      <NavLink to="/" className="nav-item">
                        Home
                      </NavLink>
                    </li>
                    <li className="menu-item">
                      <Link to="/service" className="nav-item">
                        Service
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/company" className="nav-item">
                        Company
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/case-studies" className="nav-item">
                        Case Studies
                      </Link>
                    </li>
                    {/* <li className="menu-item">
                      <Link to="/blog" className="nav-item">
                        Blog
                      </Link>
                    </li> */}
                  </ul>
                  {/* <button
                    className="nav-btn nav-close-btn"
                    onClick={showNavbar}
                  >
                    <FaTimes />
                  </button> */}
                </nav>
                {/* <button className="nav-btn" onClick={showNavbar}>
                  <FaBars />
                </button> */}
              </div>
              <div className="col-md-2">
                <div className="c-btn">
                  <p>+91-9971658013</p>
                </div>
              </div>
              <div className="col-md-2">
              <button className="contact-bt" ><Link to="/contactnew" className="nav-item" style={{color: 'white'}}>
                        Contact Us
                      </Link></button>
                {/* <button className="contact-bt">Contact Us</button> */}
              </div>
            </div>
          </div>
        </header>
      </section>

      {/* Mobile Header */}
      <section className="mobile-header">
        <header>
          <Link to="/">
            <img
              width="160"
              height="50"
              src={Banner12}
              className="site-logo"
              alt="Clinic Manager Logo"
            />
          </Link>
          <div className="dropdown">
            <button
              className="btn btn-secondary newbutton"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={isOpen}
            >
             <IoMenuOutline/>
            </button>
            <ul className={`dropdown-menu${isOpen ? " show" : ""}`}>
              <li>
                <NavLink className="dropdown-item" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <Link className="dropdown-item" to="/service">
                  Services
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/company">
                  Company
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="case-studies">
                  Case Studies
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="blog">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </header>
      </section>

      {/* Marquee Section */}
      <section className="marquee-section">
        <div className="marquee-container">
          <div className="marquee-column">
            <div className="marquee-widget-wrap">
              <div className="marquee-widget-container">
                <div className="marquee">
                  <p>
                    Generate Daily Patient Leads & Sales For Your Clinic,
                    Hospital Or Healthcare Brand Call Now +91-9971658013
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
