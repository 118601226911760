import React from "react";
import "./App.css";
import Navbar from "./Component/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import { Routes, Route } from "react-router-dom";
import Service from "./Page/Service";
import Company from "./Page/Company";
import Patient from "./Card/Patient";
import Medical from "./Card/Medical";
import Seo from "./Card/Seo";
// import Media from "./Card/Media";
import Landing from "./Card/Landing";
import Media from "./Card/Media/index";
import Shoot from "./Card/Shoot/index";
import Reputation from "./Card/Reputation";
import Social from "./Card/Social";
import ContLog from "./Component/ContLog/index";
import CaseStudies from "./Page/CaseStudies";
import Contact from "./Page/Contact";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navbar />} />
        <Route path="/service" element={<Service />} />
        <Route path="/company" element={<Company />} />
        <Route path="/solutions" element={<Service />} />
        <Route path="/contact" element={<ContLog />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/contactnew" element={<Contact />} />
        <Route path="/blog" element={<Service />} /> 
        <Route path="/patient" element={<Patient />} />
        <Route path="/medical" element={<Medical />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/media" element={<Media />} />
        <Route path="/shoot" element={<Shoot />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/online" element={<Reputation />} />
        <Route path="/social" element={<Social />} />
      </Routes>
    </>
  );
}

export default App;
